
import { Slide, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const contactNo = '+919810690986';

const CustomToast = () => (
  <div className='italic flex flex-row font-bold text-black  text-center'>
    <div className="flex flex-row">
      {/* <div className="rounded-full">
        <img src="./images/backgroundImg.png" height={70} width={50} className="rounded-full" />
      </div> */}
      <div className="flex items-center justify-center">
        <div>
      Contact us at :&nbsp;
        </div>
      </div>
    </div>
    <div className='font-bold py-3 text-sky-500 underline  cursor-pointer hover:text-sky-900' onClick={(e) => {
      navigator.clipboard.writeText(e.target.innerText)
    }}>{contactNo}</div>
  </div>
);

export const contact = async () => {
  // Get the width of the screen
  const screenWidth = window.innerWidth;
  const mobileWidthThreshold = 1024;

  if (screenWidth < mobileWidthThreshold) {
    window.open('tel:+919810690986');
  } else {
    if (!toast.isActive('alert')) {
      toast(<CustomToast />, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        style: {
          background: "linear-gradient(rgba(177, 220, 255) 0%, rgba(230, 240, 255) 100%)",
          boxShadow: "0 0 2px #000000",
          borderTopRightRadius:"30px",
          borderBottomLeftRadius:"30px",
          padding: "20px",
        },
        progress: undefined,
        theme: "light",
        transition: Slide,// Add a class name to apply custom styling
        toastId: 'alert'
      })
    }

  }
}