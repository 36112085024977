import React from 'react'
import AboutMe from '../components/HomePage/AboutMe'
import Treatments from '../components/HomePage/Treatments'

function Home() {
  return (
    <>
    <AboutMe/>
    <Treatments/>
    </>
    
  )
}

export default Home
